import React from 'react';
import './About.css';
import mcmaster4 from '../../Assets/mcmaster4.jpg'
import profile from '../../Assets/profile.jpg'
import {FaAward} from 'react-icons/fa'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
    <section id='About'>
      {/* Add a line for h5? */}
      <h2>About me</h2>

      <div className='container about_container'>
        <div className='about_me'>
          <div className="about_image">
            <img src={profile} alt=""></img>
          </div>
        </div>

        <div className="about_content">
          <div className="about_cards">
            <article className='about_card'>
              <FaAward className='icon' />
              <h5>Experience</h5>
              <small>2+ Year</small>
            </article>
            <article className='about_card'>
              <VscFolderLibrary className='icon' />
              <h5>Projects</h5>
              <small>5+ Completed</small>
            </article>
           </div>
          <p>
            I am a recent graduate with a Bachelor's degree in Computer Science from McMaster University
            and have aqcuired 16 months of Full-Stack development experience working at Arcelormital Dofasco
            as a Software Developer Intern.
          </p>

          <p>
            I am currently working as a Software Engineer at Evertz Microsystems.
          </p>
           <a href='https://www.linkedin.com/in/talha-amjad01/' className='btn btn-primary'>Lets Connect</a>
        </div>
      </div>
    </section>

  )
}

export default About